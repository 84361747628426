import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.less";

const Country = ({ item }) => (
    <div className={styles.item}>
        <img className={styles.itemImage} src={item.logo_url} alt={item.name} />
        <div className={styles.itemBody}>
            <div className={styles.itemTitle}>{item.name}</div>
        </div>
    </div>
);

Country.propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Country;
