import React from "react";
import PropTypes from "prop-types";
import dateFns from "date-fns";

import styles from "./styles.less";

const Meet = ({ item }) => {
    const { city, state, country, startdate } = item;
    const startDate = dateFns.format(startdate, "MMMM D, YYYY");
    const location = () => {
        const arr = [];
        if (city) arr.push(city);
        if (state) arr.push(state);
        if (country) arr.push(country);
        return arr.join(", ");
    };

    return (
        <div className={styles.item}>
            <div className={styles.itemBody}>
                <div className={styles.itemTitle}>{item.name}</div>
                <div className={styles.itemMeta}>
                    {startDate} - {location()}
                </div>
            </div>
        </div>
    );
};

Meet.propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Meet;
