import React from "react";
import ReactDOM from "react-dom";

import { initAxios } from "~/shared/utils";

import App from "./App";

initAxios();

const container = document.getElementById("global-search");
const { dataset } = container || {};
const { globalSearchItems } = dataset || {};
const initialItems = globalSearchItems ? JSON.parse(globalSearchItems) : [];

ReactDOM.render(<App initialItems={initialItems} />, container);
