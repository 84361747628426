import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import Swimmer from "./OptionTemplates/Swimmer";
import Division from "./OptionTemplates/Division";
import Meet from "./OptionTemplates/Meet";
import Team from "./OptionTemplates/Team";
import State from "./OptionTemplates/State";
import Country from "./OptionTemplates/Country";
import Lsc from "./OptionTemplates/Lsc";
import HighSchoolRegion from "./OptionTemplates/HighSchoolRegion";

function getTemplateForItem(item) {
    const { source } = item;

    switch (source) {
        case "Divisions":
            return <Division item={item} />;
        case "Conferences":
            return <Country item={item} />;
        case "States":
            return <State item={item} />;
        case "Teams":
            return <Team item={item} />;
        case "Swimmers":
            return <Swimmer item={item} />;
        case "Meets":
            return <Meet item={item} />;
        case "Lscs":
            return <Lsc item={item} />;
        case "Countrys":
            return <Country item={item} />;
        case "High school regions":
            return <HighSchoolRegion item={item} />;
        default:
            return <Country item={item} />;
    }
}

const Option = (props) => {
    const { data } = props;

    const template = getTemplateForItem(data);

    return <components.Option {...props}>{template}</components.Option>;
};

Option.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

export default Option;
