import _ from "lodash";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import objectHash from "object-hash";
import classNames from "classnames";

import AllCheckbox from "./AllCheckbox";

import styles from "./BulkBar.less";

export const getDefaultSelection = () => ({
    includedIds: [],
    excludedIds: [],
    isAll: false,
    isAllAtPage: false,
    selectedCount: 0,
    indeterminate: false,
    checked: false,
});

const explicateSelection = ({ selection, response }) => {
    const { isAll, includedIds, excludedIds } = selection;
    const copy = {
        ...getDefaultSelection(),
        includedIds,
        excludedIds,
        isAll,
    };
    if (!response) return copy;
    const { results, count: total } = response;
    const includedCount = includedIds.length;
    const hasIncluded = !!includedCount;
    const excludedCount = excludedIds.length;
    const hasExcluded = !!excludedCount;
    //  annotate isAllAtPage
    if (results && results.length) {
        const ids = results.map((r) => r.id);
        copy.isAllAtPage = _.isEqual(_.sortBy(ids), _.sortBy(includedIds));
    }
    // annotate selectedCount
    if (isAll) copy.selectedCount = total - excludedCount;
    else copy.selectedCount = includedCount;
    // annotate checked/indeterminate
    copy.indeterminate = false;
    copy.checked = false;
    if (isAll) {
        if (hasExcluded) copy.indeterminate = true;
        else copy.checked = true;
    } else if (copy.isAllAtPage) {
        copy.checked = true;
    } else if (hasIncluded) {
        copy.indeterminate = true;
    }
    return copy;
};

export const onSelectAll = ({ response, onChange }) => {
    const nextSelection = getDefaultSelection();
    if (response && response.results) nextSelection.isAll = true;
    onChange(explicateSelection({ selection: nextSelection, response }));
};

export const onSelectAllInPage = ({ response, onChange }) => {
    const nextSelection = getDefaultSelection();
    if (response && response.results) {
        const { results } = response;
        nextSelection.includedIds = results.map((r) => r.id);
    }
    onChange(explicateSelection({ selection: nextSelection, response }));
};

export const onClearAll = ({ response, onChange }) => {
    onChange(
        explicateSelection({ selection: getDefaultSelection(), response }),
    );
};

const BulkBar = (props) => {
    const {
        selection,
        response,
        onChange,
        actions,
        placeholder,
        className,
        isTableHeader,
        showSelectAll,
    } = props;
    const { selectedCount, isAllAtPage, isAll } = selection;
    const btnClassName = "btn btn-link";

    useEffect(() => {
        const nextSelection = explicateSelection({ selection, response });
        if (objectHash(nextSelection) !== objectHash(selection))
            onChange(nextSelection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, selection]);

    const renderCounter = () =>
        Boolean(selectedCount) && (
            <span className="u-nowrap">{selectedCount} selected</span>
        );

    const renderSelectAll = () =>
        showSelectAll &&
        isAllAtPage &&
        selectedCount !== response.count && (
            <button
                type="button"
                className={btnClassName}
                onClick={() => {
                    onSelectAll({ response, onChange });
                }}
            >
                Select all {response.count}
            </button>
        );

    const renderClearAll = () =>
        isAll && (
            <button
                type="button"
                className={btnClassName}
                onClick={() => {
                    onClearAll({ response, onChange });
                }}
            >
                Clear
            </button>
        );

    const renderActions = () => (selectedCount ? actions : placeholder);

    return (
        <div
            className={classNames(
                styles.bar,
                className,
                isTableHeader && styles.barTableHeader,
            )}
        >
            <div className={styles.selection}>
                <AllCheckbox
                    selection={selection}
                    onSelectAllInPage={() => {
                        onSelectAllInPage({ response, onChange });
                    }}
                    onClearAll={() => {
                        onClearAll({ response, onChange });
                    }}
                />
                {renderCounter()}
                {renderSelectAll()}
                {renderClearAll()}
            </div>
            {renderActions()}
        </div>
    );
};

BulkBar.defaultProps = {
    placeholder: null,
    actions: null,
    className: null,
    isTableHeader: false,
    showSelectAll: true,
};

BulkBar.propTypes = {
    selection: PropTypes.objectOf(PropTypes.any).isRequired,
    response: PropTypes.shape({
        count: PropTypes.number.isRequired,
        results: PropTypes.arrayOf(PropTypes.any).isRequired,
    }).isRequired,
    placeholder: PropTypes.node,
    actions: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isTableHeader: PropTypes.bool,
    showSelectAll: PropTypes.bool,
};

export default BulkBar;
